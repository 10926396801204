import Vue from 'vue'
import axios from 'axios'
import AxiosPlugin from 'vue-axios-cors'

Vue.use(AxiosPlugin)
const client = axios.create({
  baseURL: 'https://d0mqq2u37k.execute-api.ap-southeast-1.amazonaws.com/dev/',
  json: true,
})

export default {
  async callAuthoriseApi () {
    return client({
      method: 'get',
      url: '/myinfo/get_authurl',
      headers: { },
      data: { },
    }).then(req => {
      return req
    }).catch(error => {
      return error.response
    })
  },
  async createTokenRequest (data) {
    return client({
      method: 'post',
      url: '/myinfo/createtoken',
      headers: { },
      data,
    }).then(req => {
      return req
    }).catch(error => {
      return error.response
    })
  },
}
